import React, { useState } from "react"
import { Link } from "gatsby"

const Navbar = () => {
  const [show, setShow] = useState(true)
  return (
    <div>
      <div className="header">
        <nav className="nav">
          <Link to="/">
            <img src={"/logo.svg"} alt="Logo" className="navLogo" />
          </Link>
          <div
            onClick={() => setShow(s => !s)}
            onKeyDown={() => setShow(s => !s)}
            role="button"
            tabIndex="0"
            className="navBurgerContainer"
          >
            <img
              src={"/burger.svg"}
              alt="Menü"
              className="navBurger"
              style={{ display: show ? "block" : "none" }}
            />
          </div>
          <div
            onClick={() => setShow(s => !s)}
            onKeyDown={() => setShow(s => !s)}
            role="button"
            tabIndex="0"
            className="navBurgerContainer"
          >
            <img
              src={"/burgercancel.svg"}
              alt="Menü schließen"
              className="navBurger"
              style={{ display: show ? "none" : "block" }}
            />
          </div>
        </nav>

        <ul className="navText" style={{ display: show ? "none" : "block" }}>
          <li>
            <Link to="/" onClick={() => setShow(s => !s)}>
              Start
            </Link>
          </li>
          <li>
            <Link to="/kontakt" onClick={() => setShow(s => !s)}>
              Kontakt
            </Link>
          </li>
          <li>
            <Link to="/leistungen" onClick={() => setShow(s => !s)}>
              Leistungen
            </Link>
          </li>
          <li>
            <Link to="/praxis" onClick={() => setShow(s => !s)}>
              Praxis
            </Link>
          </li>
          <li>
            <Link to="/news" onClick={() => setShow(s => !s)}>
              News
            </Link>
          </li>

          <li>
            <Link to="/team" onClick={() => setShow(s => !s)}>
              Team
            </Link>
          </li>
        </ul>

        <div className="phone" style={{ display: show ? "block" : "none" }}>
          <a href="tel:03172-89052">
            <img
              src={"/phone.png"}
              alt="Telefon Nummer"
              className="phoneIcon"
            />
            &nbsp;03172-89052
          </a>
        </div>
      </div>

      <div className="navResponsive">
        <div className="navResponsiveBox">
          <Link to="/">
            <img src={"/logo.svg"} alt="Logo" className="navLogo" />
          </Link>
          <div className="navResponsiveText">
            <p>
              <Link to="/">Start</Link>
            </p>
            <p>
              <Link to="/kontakt">Kontakt</Link>
            </p>
            <p>
              <Link to="/leistungen">Leistungen</Link>
            </p>
            <p>
              <Link to="/praxis">Praxis</Link>
            </p>
            <p>
              <Link to="/news">News</Link>
            </p>

            <p>
              <Link to="/team">Team</Link>
            </p>
          </div>
        </div>

        <div className="phone2">
          <a href="tel:03172-89052">
            <img
              src={"/phone.png"}
              alt="Telefon Nummer"
              className="phoneIcon"
            />
            &nbsp;03172-89052
          </a>
        </div>
      </div>
      <div className="navSpacer"></div>
    </div>
  )
}

export default Navbar
