import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, article, microdata }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
    site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description ? defaultDescription : null,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang="de" />
      {seo.description && <meta name="description" content={seo.description} />}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {microdata && (
        <script type="application/ld+json">{`
                    {
                        "@context": "http://schema.org",
                        "@type": "LocalBusiness",
                        "@id": "krametter neuro-praxix-weiz",
                        "name": "Dr. Dieter Krametter",
                        "url": "https://www.krametter.at",
                        "image": "https://www.krametter.at/logo.svg",
                        "logo": "https://www.krametter.at/logo.svg",
                        "legalName": "Dr. Dieter Krametter",
                        "description": "${seo.description}",
                        "email": "praxis@krametter.at",
                        "telephone": "+43 3172 89052",
                        "openingHours": "Mo 08:00-11:00, Tu 08:00-11:00, We 08:00-11:00, Th 14:00-16:00, Fr 08:00-11:00",
                        "priceRange": "$",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Hans-Sutter-Gasse 3",
                            "addressLocality": "Weiz",
                            "addressRegion": "Styria",
                            "postalCode": "8160",
                            "addressCountry": "Austria"
                        },
                        "founders": [
                            {
                                "@type": "Person",
                                "givenName": "Dieter",
                                "familyName": "Krametter",
                                "jobTitle": "CEO",
                                "email": "praxis@krametter.at"
                            },
                        ],
                        "rating": {
                            "@type": "AggregateRating",
                            "ratingValue": "5",
                            "reviewCount": "24",
                            "itemReviewed": {
                                "@type": "LocalBusiness",
                                "@id": "krametter neuro-praxix-weiz"
                            }
                        }
                    }
                `}</script>
      )}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`
