import { Link } from "gatsby"
import React from "react"
import CookieConsent from "react-cookie-consent"

const Footer = () => {
  return (
    <div>
      <div className="cookieBanner">
        <CookieConsent
          location="bottom"
          buttonText="Cookies zulassen"
          declineButtonText="nur ausgewählte"
          cookieName="krametter.at"
          style={{
            background: "#2B373B",
            fontSize: "18px",
            maxWidth: "800px",
            margin: "0 auto",
          }}
          buttonStyle={{
            color: "white",
            background: "#3a9359",
            fontSize: "18px",
            borderRadius: "3px",
          }}
          declineButtonStyle={{
            color: "black",
            background: "#e3e3e3",
            fontSize: "14px",
            borderRadius: "3px",
          }}
          enableDeclineButton
          expires={150}
        >
          <div className="cookieContainer"></div>
          Diese Seite verwendet Cookies.
          <div style={{ width: "100%", height: "10px" }}></div>
          <span style={{ fontSize: "14px" }}>
            Cookies werden auf dieser Seite ausschließlich für Analysezwecke
            genutzt. Um fortzufahren, klicken Sie auf <b>"Cookies zulassen"</b>.
          </span>
          <div className="cookieSpacer"></div>
        </CookieConsent>
      </div>
      <div className="footerSpacer"></div>
      <footer className="footer">
        <div>
          <p>
            <Link to="../impressum">Impressum & Datenschutz</Link>{" "}
          </p>
          <p>
            <img src={"/copyright-48.png"} alt="Copyright" width="12px" />
            &nbsp;{new Date().getFullYear()} Dr. Dieter Krametter
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
